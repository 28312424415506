import React from "react";
import { Carousel } from "react-bootstrap";
import car1 from './car1.jpg'
import car2 from './car2.jpg'
import car3 from './car3.jpg'

const BSCarousel = () => {
  return (
    <div>
      <Carousel fade>
        <Carousel.Item>
          
          <img className="d-block w-100 taslacare " src={car2} alt="First slide"/>
          <Carousel.Caption>
            <h1 className="carouselmsg">Production of Tasla</h1>
            <p className="carouselpara">We have a large Manufacturing Unit for the production of scaleable and robust Tasla, which is available in wide range and on time.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 taslacare " src={car1} alt="Second slide"/>
          <Carousel.Caption>
            <h1 className="carouselmsg">Usage of Tasla</h1>
            <p className="carouselpara">This huge variety of Talsa used in daily life purpose, in agriculture purpose and for the construction purpose also.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 taslacare " src={car3} alt="Third slide"/>
          <Carousel.Caption>
            <h1 className="carouselmsg">Variety of Tasla</h1>
            <p className="carouselpara">We provide wide range of Talsa which is excellent in quality and fulfills all your demands.  
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default BSCarousel;
