import React from 'react';
import bucket from './Bucket.jpg';



const IronBucket=()=>{
    return (
        <div className='container commonforall'>
            <div className='row rowtasla1'>
                <h1>IRON BUCKET</h1>
                <div className="col-md-4">
                </div>   
                <div className="col-md-4">
                    <div className="card my-3">
                        <div className="card-body">
                            {/* <div className="d-flex align-items-center"> */}
                                <img  className="img-tagari" src={bucket} alt="Third slide"/>
                            {/* </div> */}
                            <p className="card-text">Iron Bucket</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">   
                </div>       
            </div>  
        </div>  
    )
}
export default IronBucket;