import React from 'react';
import tagari from './GPCoils.jpg';



const GPCoils=()=>{
    return (
        <div className='container commonforall'>
            <div className='row rowtasla1'>
                <h1>GP Coils & Sheets </h1>
                <div className="col-md-4">
                  
                </div>   
                
                <div className="col-md-4">
                    <div className="card my-3">
                        <div className="card-body">
                        {/* <div className="d-flex align-items-center"> */}
                        <img  className="img" src={tagari} alt="Third slide"/>
                            
                        {/* </div> */}

                        <p className="card-text">GP Coils & Sheets of size 0.25 to 1 mm available of various companies(ASAIAN ,BHUSHAN ,TATA ,JINDAL ,GAURANG ,AMNS ,INDIAN ,JSW )</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                   
                </div>       
                
            </div>  
         </div>  
      )

}
export default GPCoils;