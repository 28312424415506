import React from 'react';
import ghamela from './IronGhamela.jpg';



const IronGhamela=()=>{
    return (
        <div className='container commonforall'>
            <div className='row rowtasla1'>
                <h1>IRON GHAMELA</h1>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img className="img-tasla" src={ghamela} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">10 inch Iron Ghamela</p>
                        </div>
                    </div>
                </div>   
                
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                            <img  className="img-tasla" src={ghamela} alt="Third slide"/>
                        </div>

                        <p className="card-text">14 inch Iron Ghamela</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={ghamela} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">16 inch Iron Ghamela</p>
                        </div>
                    </div>
                </div>       
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img className="img-tasla" src={ghamela} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">18 inch Iron Ghamela</p>
                        </div>
                    </div>
                </div>   
            </div>  
            <div className='row rowtasla2'>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img className="img-tasla" src={ghamela} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">20 inch Iron Ghamela</p>
                        </div>
                    </div>
                </div>   
                
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={ghamela} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">22 inch Iron Ghamela</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={ghamela} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">24 inch Iron Ghamela</p>
                        </div>
                    </div>
                </div>       
            </div>  
         </div>  
      )

}
export default IronGhamela;