import React from 'react';
import tasla10 from './10-Inch-Iron-Tasla.jpg';
import tasla14 from './14-Inch-Iron-Tasla.jpg';
import tasla16 from './16-Inch-Iron-Tasla.jpg';
import tasla18 from './18-Inch-Iron-Tasla.jpg';


const IronTasla=()=>{
    return (
        <div className='container commonforall'>
            <div className='row rowtasla1'>
                <h1>IRON TASLA</h1>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img className="img-tasla" src={tasla10} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">10 inch Iron Tasla</p>
                        </div>
                    </div>
                </div>   
                
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={tasla14} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">14 inch Iron Tasla</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={tasla16} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">16 inch Iron Tasla</p>
                        </div>
                    </div>
                </div>       
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img className="img-tasla" src={tasla10} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">18 inch Iron Tasla</p>
                        </div>
                    </div>
                </div>   
            </div>  
            <div className='row rowtasla2'>
                
                
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={tasla18} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">20 inch Iron Tasla</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={tasla16} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">22 inch Iron Tasla</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={tasla16} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">24 inch Iron Tasla</p>
                        </div>
                    </div>
                </div>    
            </div>  
        </div>
        
      )

}
export default IronTasla;