import React from 'react';
import tagari from './Tagari.jpg';



const IronTagari=()=>{
    return (
        <div className='container commonforall'>
            <div className='row rowtasla1'>
                <h1>IRON TAGARI</h1>
                <div className="col-md-4">
                  
                </div>   
                
                <div className="col-md-4">
                    <div className="card my-3">
                        <div className="card-body">
                        {/* <div className="d-flex align-items-center"> */}
                        <img  className="img-tagari" src={tagari} alt="Third slide"/>
                            
                        {/* </div> */}

                        <p className="card-text">Iron Tagari</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                   
                </div>       
                
            </div>  
         </div>  
      )

}
export default IronTagari;