import React from 'react';
import tasla from './18-Inch-Iron-Tasla.jpg';
import bucket from './Bucket.jpg';
import paraat from './Paraat.jpg';
import tagari from './Tagari.jpg';
import ghamela from './IronGhamela.jpg';
import gpcoil from './GPCoils.jpg';
import { Link } from 'react-router-dom';

const Products = () => {
  return (
    <div className='container commonforall'>
    <div className='row rowtasla1'>
        <h1>PRODUCTS RANGE</h1>
        <div className="col-md-3">
            <div className="card my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <Link to="/irontasla">
                      <img className="img-products" src={tasla} alt="Third slide"/>
                    </Link>    
                  </div>
                <p className="card-text">Iron Tasla</p>
                </div>
            </div>
        </div>   
        
        <div className="col-md-3">
            <div className="card my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <Link to="/ironghamela">
                      <img  className="img-products" src={ghamela} alt="Third slide"/>  
                    </Link>
                  </div>
                <p className="card-text">Iron Ghamela</p>
                </div>
            </div>
        </div>
        <div className="col-md-3">
            <div className="card my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <Link to="/ironparaat">
                      <img  className="img-products" src={paraat} alt="Third slide"/>
                    </Link>
                  </div>
                <p className="card-text">Iron Paraat</p>
                </div>
            </div>
        </div>       
        <div className="col-md-3">
            <div className="card my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <Link to="/irontagari">
                      <img className="img-products" src={tagari} alt="Third slide"/>       
                    </Link>
                  </div>
                <p className="card-text">Iron Tagari</p>
                </div>
            </div>
        </div>   
    </div>  
    <div className='row rowtasla2'>
        <div className='col-md-3'></div>
        <div className="col-md-3">
            <div className="card my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <Link to="/ironbucket">
                      <img  className="img-products" src={bucket} alt="Third slide"/>  
                    </Link>
                  </div>

                <p className="card-text">Iron Bucket</p>
                </div>
            </div>
        </div>
        <div className="col-md-3">
            <div className="card my-3">
                <div className="card-body">
                <div className="d-flex align-items-center">
                  <Link to="/gpcoils">
                    <img  className="img-products" src={gpcoil} alt="Third slide"/>
                  </Link>
                </div>
                <p className="card-text">GP Coils & Sheets</p>
                </div>
            </div>
        </div>
        <div className='col-md-3'></div>
    </div>  
</div>
  )
}

export default Products