import React from 'react'
import imgabout from './aboutimg.jpg';

const About = () => {
  return (
    // <div className='container'>
    //   <div className='row '>
    //     <div className='col-md-12 aboutinfo'>
    //       <h4 className='heading'>About Us</h4>
    //       <img src={imgabout}/>
    //       <p className='para'>Shree Jee Agrico is Founded by Mr. Ashok Kumar Jain who is one of the oldest Manufacturers and Suppliers in dealing wide range of tasla.
    //         We provide different varities of Tasla comprises Metal Tasla, Iron Tasla, Iron Ghamela,Iron Paraat,Iron Tagari,Iron Bucket and Gp Coils(of different companies) and all of these varities are available in
    //         different sizes, according to the need of our customer and delivered on time.
    //       </p>
          
    //       <div className="d-flex justify-content-around ">
    //         <div className="fon"><i className="fa-solid duotone fa-q fonts " id="qualityfont"></i><p className='pfont' id="quality">100 % <br/>Assured Quality</p></div>
    //         <div className="fon"><i className="fa-solid fa-business-time fonts " id="timefont"></i><p className='pfont' id="time">On Time<br/>Delivery</p></div>
    //         <div className="fon" ><i className="fa-solid fa-plus-minus fonts " id="sizefont"></i><p className='pfont' id="size">Availability of<br/>Dfifferent Sizes</p></div>  
    //       </div>
    //       </div>
    //   </div>
    // </div>
    <div className='container-fluid contactdiv'>
    <div className='row contactinfo'>
      <div className='col-md-6'>
        <img className="aboutimg" src={imgabout}></img>
      </div>
      <div className='col-md-6 '>
        <h4 className='heading'>ABOUT US 
          <p className='para content'>
          Shree Jee Agrico is Founded by Mr. Ashok Kumar Jain who is one of the oldest Manufacturers and Suppliers in dealing wide range of tasla.
            We provide different varities of Tasla comprises Metal Tasla, Iron Tasla, Iron Ghamela,Iron Paraat,Iron Tagari,Iron Bucket and Gp Coils & Sheets (of different companies) and all of these varities are available in
           different sizes, according to the need of our customer and delivered on time.
          </p>
          <div className="d-flex justify-content-around ">
            <div className="fon"><i className="fa-solid duotone fa-q fonts " id="qualityfont"></i><p className='pfont' id="quality">100 % <br/>Assured Quality</p></div>
             <div className="fon"><i className="fa-solid fa-business-time fonts " id="timefont"></i><p className='pfont' id="time">On Time<br/>Delivery</p></div>
            <div className="fon" ><i className="fa-solid fa-plus-minus fonts " id="sizefont"></i><p className='pfont' id="size">Availability of<br/>Dfifferent Sizes</p></div>  
          </div>
        </h4>
      </div>   
    </div>
   </div> 
  )
}

export default About 