import React from "react";
//import "./style.css";
import BSCarousel from "./BSCarousel";
const Home = () => {
  return (
      <div className="container bgimg">
        {/* <div className="row">
          <div className="col-md-6 ">
            <h1 className="welcome">
              Welcome to Shree Jee <br />
              Agrico
            </h1>
            <BSCarousel/>
          </div>
          <div className="col-md-6"></div>
        </div> */}
        <div className="row homebg">
          {/* <h1 className="welcome">
            Welcome to Shree Jee Agrico */}
            <BSCarousel/>
          {/* </h1> */}
        </div>
      </div>
     
    
  );
};

export default Home;
