import React from 'react'
import contact from './contact.jpg';

const Enquiry = () => {
  return (
    <div className='container-fluid contactdiv'>
      <div className='row contactinfo'>
        <div className='col-md-5 '>
        <h4 className='heading enheading'>CONTACT US 
            <p className='para content'>
            If you have any questions or queries, 
            Feel free to ask us anything!
            You could contact us by telephone or email and we will be sure to get back
            to you as soon as possible.
            </p></h4>
            </div>
          {/* <div className="d-flex flex-row ">
            <h4 className='heading enheading'>CONTACT US 
            <p className='para content'>
            If you have any questions or queries, 
            Feel free to ask us anything!
            You could contact us by telephone or email and we will be sure to get back
            to you as soon as possible.
            </p></h4>
            {/* <i className="fa-regular fa-comment large" style={{fontSize:"21rem",paddingTop:"2rem",paddingLeft:"2rem"}}>
            <span>
              <p style={{fontSize:"1.5rem"}}>Call Us: </p>
              <p  style={{fontSize:"1.5rem"}}>Mr. Ashok Jain : +91-9310005731</p>
              <p  style={{fontSize:"1.5rem"}}>Mr. Ankur Jain : +91-9355005731</p>
              <p style={{fontSize:"1.5rem"}}>Email Us:</p>
              <p style={{fontSize:"1.5rem"}}>shreejeeagrico@gmail.com</p>
            </span></i> 
            <img className="enimg" src={contact}></img>
          </div> */}
        
         <div className='col-md-7'>
         <img className="enimg" src={contact}></img>
        </div>
      </div>
     </div> 
  )
}

export default Enquiry