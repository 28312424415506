import React from 'react'
//import './style.css';
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg  fixed-top navClass">
        <div className="container-fluid">
          <img className="logo" src="logomain.jpg" alt=""/>
          <span className='industry_name'>  AGRICO INDUSTRIES</span>
          <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon toggleicon"></span>
          </button>   
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mright">
              <li className="nav-item"><Link className="nav-link pages" aria-current="page" to="/">Home</Link></li>
              <li className="nav-item"><Link className="nav-link pages" to="/about">About</Link></li>
               <li className="nav-item dropdown"> 
              {/* <li className="nav-item"><Link className="nav-link pages" to="/products" >Products</Link></li> */}
                 <Link className="nav-link dropdown-toggle pages" to="/products" data-toggle="dropdown" >
                   Products
                </Link> 
                 <ul className="dropdown-menu dropitem" >
                  <li><Link className="dropdown-item" to="/irontasla">Iron Tasla</Link></li> <hr className="dropdown-divider "/>
                  <li><Link className="dropdown-item" to="/ironparaat">Iron Paraat</Link></li><hr className="dropdown-divider "/>
                  <li><Link className="dropdown-item" to="/ironghamela">Iron Ghamela</Link></li><hr className="dropdown-divider "/>
                  <li><Link className="dropdown-item" to="/irontagari">Iron Tagari</Link></li><hr className="dropdown-divider "/>
                  <li><Link className="dropdown-item" to="/ironbucket">Iron Bucket</Link></li><hr className="dropdown-divider "/>
                  <li><Link className="dropdown-item" to="/gpcoils">GP Coils</Link></li>
                </ul> 
               </li> 
              <li className="nav-item"><Link className="nav-link pages" to="/enquiry" >Contact</Link></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar