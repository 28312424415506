import React from "react";

const Footer = () => {
  return (
    <div className="container-fluid foot">
      <div className="row contact-us">
          <p>Contact Us</p>
          <hr className="line"/>
      </div>
      <div className="row">
        <div className="d-flex flex-column Address">
          <span><i className="fa-solid fa-location-dot mx-2"></i>Address : Plot No:260, New HSIIDC, Hissar Road, Rohtak, Haryana-124001</span><br/>
          <span><i className="fa-solid fa-phone mx-2"></i>Call Us : Mr. Ashok Jain (+91-9310005731), Mr. Ankur Jain (+91-9355005731) </span><br/>
          <span><i className="fa-solid fa-envelope mx-2"></i>Email : shreejeeagrico@gmail.com</span>
        </div>
        <div className="col-md-6 callus">
        </div>
      </div>
    </div>
  );
};

export default Footer;
