import "./App.css";
import "./style.css";
import Footer from "./components/Footer";
//import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Products from "./components/Products";
import Enquiry from "./components/Enquiry";
import IronTasla from "./components/IronTasla";
import IronParaat from "./components/IronParaat";
import { BrowserRouter as Router , Switch, Route } from "react-router-dom";
import IronGhamela from "./components/IronGhamela";
import IronTagari from "./components/IronTagari";
import IronBucket from "./components/IronBucket";
import GPCoils from "./components/GPCoils";


function App () {
  return (
   <>
    <Router>
      <div className="App">
        <Navbar />
          <Switch>
            <Route exact path="/" ><Home key="home" category="home"/></Route>
            <Route exact path="/about" ><About key="about" category="about"/></Route>
            <Route exact path="/products"><Products key="products" category="products"/>
              
            </Route>
            <Route exact path="/irontasla"><IronTasla key="irontasla" category="irontasla"/></Route>
            <Route exact path="/ironparaat"><IronParaat key="ironparaat" category="ironparaat"/></Route>
            <Route exact path="/ironghamela"><IronGhamela key="ironghamela" category="ironghamela"/></Route>
            <Route exact path="/irontagari"><IronTagari key="irontagari" category="irontagari"/></Route>
            <Route exact path="/ironbucket"><IronBucket key="ironbucket" category="ironbucket"/></Route>
            <Route exact path="/gpcoils"><GPCoils key="gpcoils" category="gpcoils"/></Route>
            <Route exact path="/enquiry" ><Enquiry key="enquiry" category="enquiry"/></Route>    
          </Switch>
        <Footer/>
      </div> 
    </Router>
    
   </>   
  );
};

export default App;
