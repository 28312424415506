import React from 'react';
import paraat from './Paraat.jpg';



const IronParaat=()=>{
    return (
        <div className='container commonforall'>
            <h1>IRON PARAAT</h1>
            <div className='row rowtasla1'>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img className="img-tasla" src={paraat} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">14 inch Iron Paraat</p>
                        </div>
                    </div>
                </div>   
                
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={paraat} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">16 inch Iron Paraat</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img  className="img-tasla" src={paraat} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">18 inch Iron Paraat</p>
                        </div>
                    </div>
                </div>       
                <div className="col-md-3">
                    <div className="card my-3">
                        <div className="card-body">
                        <div className="d-flex align-items-center">
                        <img className="img-tasla" src={paraat} alt="Third slide"/>
                            
                        </div>

                        <p className="card-text">20 inch Iron Paraat</p>
                        </div>
                    </div>
                </div>   
            </div>  
         </div>  
      )

}
export default IronParaat;